import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";

import { Container } from "../../styles/UI";


import * as S from "./style";
import FormInput from "../../DesignSystem/FormInput";
import Button from "../../DesignSystem/Button";
import { useInput } from "../../hooks/useInput";
import StyledLink from "../../DesignSystem/StyledLink";
// import Checkbox from "../../DesignSystem/Checkbox";
import ProductCard from "../../components/ProductCard";
import ApresentacaoHome from "../home/apresentacao";
import Seo from "../../components/Seo";

import axios from "axios";
import SwitchButton from "../../DesignSystem/SwitchButton";

import documentacao from "../../../static/documentacao_taxsheets.pdf";
import Dropdown from "../../DesignSystem/Dropdown";
import TestimonialsWrapper from "../../components/Testimonials";
import { Depoimentos } from "../home/depoimentos/style";
import { navigate } from "gatsby";
import Checkbox from "../../components/Checkbox";
// import setup from "../../../static/Setup_Taxcel.exe";

export default function BaixarTaxsheets(props) {
  useEffect(() => {
    if (props.isClient) {
      setIsCliente(props.isClient === "true");
    }
  }, []);

  const {
    value: email,
    isInvalid: isInvalidEmail,
    bind: bindEmail,
    reset: resetEmail,
  } = useInput(props.email, "email");
  const {
    value: nome,
    isInvalid: isInvalidNome,
    bind: bindNome,
    reset: resetNome,
  } = useInput("");
  const {
    value: tel,
    isInvalid: isInvalidTel,
    bind: bindTel,
    reset: resetTel,
  } = useInput("", "tel");
  const {
    value: empresa,
    isInvalid: isInvalidEmpresa,
    bind: bindEmpresa,
    reset: resetEmpresa,
  } = useInput("");
  const {
    value: cargo,
    isInvalid: isInvalidCargo,
    bind: bindCargo,
    reset: resetCargo,
  } = useInput("");
  const {
    value: check,
    isInvalid: isInvalidCheckInfo,
    bind: bindCheck,
    reset: resetCheckInfo,
  } = useInput("");

  const client = axios.create({
    baseURL: `https://taxcellicenseserver2.azurewebsites.net/`,
    headers: {
      "Content-Type": `application/x-www-form-urlencoded`,
    },
  });

  const baixarSetup = () => {
    if (isCliente) {
      if (typeof window !== "undefined")
        // window.location.href = `/TaxSheets/Setup_Taxcel.exe`;
        window.location.href = `https://arquivostaxcel.blob.core.windows.net/taxcel/Instalador_TaxSheets.exe`;
      return;
    }

    if (
      email === "" ||
      isInvalidEmail ||
      nome === "" ||
      isInvalidNome ||
      tel === "" ||
      isInvalidTel ||
      empresa === "" ||
      isInvalidEmpresa ||
      !isChecked ||
      isInvalidCheckInfo ||
      !origem
    ) {
      alert("Preencha todos os campos para baixar o instalador");
      return;
    }

    //integracao RD
    const form = new URLSearchParams();

    let telNumber = tel.replace(/\D/g, "");

    // form.append("source", `BaixarTaxsheets-${origem.value}`);
    form.append("source", "formulario");
    form.append("cargo", cargo);
    form.append("email", email);
    form.append("empresa", empresa);
    form.append("telefone", tel);
    form.append("nome", nome);
    form.append("check", check);
    form.append("cf_origem_forms", origem.value);

    document.getElementById("form-baixar-taxsheets").submit();
    document.getElementById("form-baixar-taxsheets").reset();

    client.post(`/RD_Station.aspx`, form).then((resp) => {
      console.log(resp);
    });
    //------

    // "https://taxcellicenseserver2.azurewebsites.net/"
    // "source=Baixar-Teste&email=teste%40telefone.com&empresa=Teste+telefone&telefone=%2812%29+31231-3213&nome=Teste+teleofne"

    // https://taxcellicenseserver2.azurewebsites.net/RD_Station.aspx?source=BaixarTaxsheets-Instagram&cargo=Tester&job_title=Tester&trabalho=Tester&job-title=Tester&titulo_trabalho=Tester&[job_title]=Tester&email=juliano.agostineli%40gmail.com&empresa=Taxcel&telefone=%2855%29+1194-27904&nome=Juliano123&cf_origem_forms=Instagram

    console.log(nome, email, tel, empresa, isCliente, origem.value);
    // resetEmail();
    // resetEmpresa();
    // resetNome();
    // resetTel();

    if (typeof window !== "undefined")
      window.location.href = `https://arquivostaxcel.blob.core.windows.net/taxcel/Instalador_TaxSheets.exe`;

    // if (!isCliente) {
    //   setTimeout(() => {
    //     navigate("https://taxcel.com.br/documentacao_taxsheets.pdf");
    //   }, 1000);
    // }

    setIsCliente(false);
    setPixelLinkedin(true);
  };

  const [isCliente, setIsCliente] = useState(false);

  const [pixelLinkedin, setPixelLinkedin] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const [origem, setOrigem] = useState(null);
  const [size, setSize] = useState(null);
  const [position, setPosition] = useState(null);
  const [formState, setFormState] = useState({});

  const optionsOrigem = [
    {
      label: "Google",
      value: "Google",
    },
    {
      label: "LinkedIn",
      value: "LinkedIn",
    },
    {
      label: "Facebook",
      value: "Facebook",
    },
    {
      label: "Instagram",
      value: "Instagram",
    },
    {
      label: "Indicação",
      value: "Indicação",
    },
    {
      label: "Youtube",
      value: "Youtube",
    },
    {
      label: "Evento",
      value: "Evento",
    },
    {
      label: "Já utilizava em outra empresa",
      value: "JaUtilizava",
    },
    {
      label: "Outros",
      value: "Outros",
    },
  ];

  const optionsPosition = [
    {
      label: "Executivo Fiscal (C-level)",
      options: [
        {
          label: "Sócio",
          value: "sócio",
        },
      ],
    },
    {
      label: "Diretor",
      options: [
        {
          label: "Diretor de Planejamento Tributário",
          value: "diretor-planejamento",
        },
        { label: "Diretor de Compliance Fiscal", value: "diretor-compliance" },
        { label: "Diretor de Tax", value: "diretor-tax" },
      ],
    },
    {
      label: "Gerente, Head ou Superintendente",
      options: [
        {
          label: "Head de Compliance Fiscal",
          value: "head-de-compliance-fiscal",
        },
        {
          label: "Superintendente de Tax Technology",
          value: "superintendente-de-tax-technology",
        },
        {
          label: "Gerente de Planejamento Tributário",
          value: "gerente-de-planejamento-tributário",
        },
        {
          label: "Gerente de Compliance Fiscal",
          value: "gerente-de-compliance-fiscal",
        },
        {
          label: "Gerente de Tax Technology",
          value: "gerente-de-tax-technology",
        },
      ],
    },
    {
      label: "Coordenador ou Especialista",
      options: [
        {
          label: "Coordenador de Planejamento Tributário",
          value: "coordenador-de-planejamento-tributário",
        },
        {
          label: "Especialista em Compliance Fiscal",
          value: "especialista-em-compliance-fiscal",
        },
        {
          label: "Coordenador de Tax Technology",
          value: "coordenador-de-tax-eechnology",
        },
      ],
    },
    {
      label: "-----------------------------------------",
      options: [
        {
          label: "Consultor Fiscal ou Analista Fiscal",
          value: "Consultor-fiscal ou analista-fiscal",
        },
        {
          label: "Trainee ",
          value: "trainee",
        },
        {
          label: "Estagiário ",
          value: "estagiário",
        },
        {
          label: "Professor ",
          value: "professor",
        },
        {
          label: "Estudante ",
          value: "estudante",
        },
        {
          label: "Outro ",
          value: "outro",
        },
      ],
    },
  ];

  const companySize = [
    {
      label: "1-20",
      value: "1-20",
    },
    {
      label: "21-50",
      value: "21-50",
    },
    {
      label: "51-300",
      value: "51-300",
    },
    {
      label: "301-1000",
      value: "301-1000",
    },
    {
      label: "1001-5000",
      value: "1001-5000",
    },
    {
      label: "Mais de 5.000",
      value: "Mais de 5.000",
    },
  ];

  // const handleChangeCheckbox = () => {
  //   setIsCliente(!isCliente);
  // };

  const handleChangeCliente = (index) => {
    if (index === 1) {
      setIsCliente(true);
    } else {
      setIsCliente(false);
    }
  };

  return (
    <Layout comprar={true}>
      <Seo title="Baixar TaxSheets" />
      {pixelLinkedin && (
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          alt=""
          src="https://px.ads.linkedin.com/collect/?pid=10076&conversionId=7320689&fmt=gif"
        />
      )}
      <S.Hero>
        <Container>
          <div className="cta">
            <h1>
              Baixe o TaxSheets (Leitor e Editor de SPED) <br />
              Teste por 7 dias gratuitamente
            </h1>
            <p>
              Transforme arquivos fiscais do SPED em planilhas editáveis do
              Excel e dê um salto na sua produtividade.
            </p>
          </div>
          <div className="illustration">
          </div>
        </Container>
      </S.Hero>
      <S.Baixar id="formulario">
        <Container>
          <div className="form">
            <h2>Preencha o formulário e baixe o instalador</h2>
            <SwitchButton
              variant={2}
              selection={isCliente ? 1 : 0}
              handleChange={handleChangeCliente}
              options={["Ainda não sou cliente", "Já sou cliente"]}
            />
            {!isCliente ? (
              <form id="form-baixar-taxsheets">
                <FormInput
                  required
                  name="nome"
                  label="Nome"
                  mt={24}
                  placeholder="Nome Sobrenome"
                  error={isInvalidNome}
                  errorMsg="Campo obrigatório"
                  {...bindNome}
                />
                <FormInput
                  required
                  name="email"
                  label="Email"
                  type="email"
                  mt={16}
                  placeholder="nome@email.com"
                  error={isInvalidEmail}
                  errorMsg="Digite um email válido para continuar"
                  {...bindEmail}
                />
                <FormInput
                  required
                  name="tel"
                  type="tel"
                  label="Telefone"
                  mt={16}
                  placeholder=""
                  error={isInvalidTel}
                  errorMsg="Digite um telefone válido para continuar"
                  {...bindTel}
                />
                <Dropdown
                  required
                  name="cargo"
                  label="Cargo"
                  mt={16}
                  mb={16}
                  placeholder=""
                  value={position}
                  onChange={(selectedOption) => setPosition(selectedOption)}
                  options={optionsPosition}
                />
                {position?.value === "outro" && (
                  <FormInput
                    required
                    name="outroCargo"
                    label="Por favor, especifique o cargo"
                    mt={16}
                    placeholder="Descreva seu cargo"
                    value={formState.outroCargo || ""}
                    onChange={(e) =>
                      setFormState({ ...formState, outroCargo: e.target.value })
                    }
                  />
                )}
                <FormInput
                  required
                  name="empresa"
                  label="Empresa"
                  mt={16}
                  placeholder=""
                  error={isInvalidEmpresa}
                  errorMsg="Campo obrigatório"
                  {...bindEmpresa}
                />
                <Dropdown
                  required
                  label="Quantidade de colaboradores?"
                  placeholder=""
                  defaultValue={null}
                  value={size}
                  onChange={(selectedOption) => setSize(selectedOption)}
                  options={companySize}
                />
                <Dropdown
                  label="Como você conheceu a Taxcel?"
                  placeholder=""
                  defaultValue={null}
                  value={origem}
                  onChange={(selectedOption) => setOrigem(selectedOption)}
                  options={optionsOrigem}
                />
                <Checkbox
                  fs="17px"
                  label="Confirmo a exatidão das informações pessoais, ciente de seu envio à Taxcel e da minha responsabilidade sobre elas."
                  name="check"
                  checked={isChecked}
                  onChange={() => setIsChecked(!isChecked)}
                  className="custom-checkbox"
                  mt="10px"
                  mb="10px"
                />
              </form>
            ) : !props.versaoCliente ? (
              <></>
            ) : props.versaoCliente.replace(/\D/g, "") ===
              props.versao.replace(/\D/g, "") ? (
              <S.Atualizacao>
                <p>Você já possui a versão mais atualizada do TaxSheets.</p>
                <div className="versoes">
                  <div className="wrap">
                    <div className="line">
                      <p>Sua versão instalada: </p>
                      <p>{props.versaoCliente}</p>
                    </div>
                    <div className="line">
                      <p>Última versão lançada: </p>
                      <p>{props.versao}</p>
                    </div>
                  </div>
                </div>
              </S.Atualizacao>
            ) : (
              <S.Atualizacao>
                <p>
                  Você não possui a versão mais atualizada do TaxSheets. Clique
                  em “Baixar TaxSheets” para obter os últimos recursos.
                </p>
                <div className="versoes">
                  <div className="wrap">
                    <div className="line">
                      <p>Sua versão instalada: </p>
                      <p>{props.versaoCliente}</p>
                    </div>
                    <div className="line">
                      <p>Última versão lançada: </p>
                      <p>{props.versao}</p>
                    </div>
                  </div>
                </div>
              </S.Atualizacao>
            )}
            {/* <Checkbox
              label="Já sou cliente"
              mt={24}
              checked={isCliente}
              onChange={handleChangeCheckbox}
            /> */}
            <p className="versao">Versão atual: v{props.versao}</p>
            <Button
              size="big"
              fluid
              fullWidth
              center
              mt={8}
              onClick={baixarSetup}
            >
              Baixar TaxSheets
            </Button>
            {/* <p className="outrasVersoes">Baixar versão anterior do TaxSheets (v{props.versaoAnterior}): <a href="https://arquivostaxcel.blob.core.windows.net/taxcel/Setup_Taxcel.exe">Download</a></p> */}
          </div>
          <div className="requisitos">
            <div className="tabelas">
              <h3>Requisitos Mínimos</h3>
              <ul>
                <li>Windows 7 (Service Pack 1) ou posterior</li>
                <li>
                  Microsoft Office 2010 ou posterior com licença e ativado
                </li>
                <li>
                  Microsoft .Net Atualizado desde a versão 4.0 (
                  <a
                    href="https://www.microsoft.com/pt-br/download/details.aspx?id=49981"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Baixar Framework
                  </a>
                  )
                </li>
                <li>Processador Intel Core i5 ou superior (para Notebooks)</li>
                <li>
                  Processador Intel Core i3 ou superior/AMD FX 6350 ou superior
                  (para Desktops)
                </li>
                <li>4GB de memória RAM</li>
              </ul>
              <h3>Para processamento de arquivos grandes</h3>
              <table>
                <thead>
                  <tr>
                    <th>Tamanho do arquivo</th>
                    <th>Memória RAM</th>
                    <th>Windows e Office</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Até 100MB</td>
                    <td>8GB</td>
                    <td>64bits</td>
                  </tr>
                  <tr>
                    <td>100MB a 500MB</td>
                    <td>16GB</td>
                    <td>64bits</td>
                  </tr>
                  <tr>
                    <td>500MB a 1,5GB</td>
                    <td>32GB</td>
                    <td>64bits</td>
                  </tr>
                  <tr>
                    <td>Mais que 1,5GB</td>
                    <td>64GB</td>
                    <td>64bits</td>
                  </tr>
                </tbody>
              </table>
              <StyledLink external download to={documentacao}>
                Baixar manual de instalação e documentação completa
              </StyledLink>
            </div>
          </div>
          <p className="duvidas">
            Alguma dúvida?{" "}
            <StyledLink
              external
              to="https://taxceladdins.zendesk.com/hc/pt-br"
              arrow
              color="laranja"
            >
              Acesse nosso Portal de Ajuda
            </StyledLink>
          </p>
        </Container>
      </S.Baixar>
      <S.Solucoes>
        <Container $clip>
          <h5>Quer saber mais sobre o TaxSheets?</h5>
          <ProductCard fluid product="taxsheets" />
        </Container>
      </S.Solucoes>
      <ApresentacaoHome />
      <Depoimentos>
        <Container>
          <TestimonialsWrapper className="cards" />
        </Container>
      </Depoimentos>
      <S.Solucoes>
        <Container $clip>
          <h5>Conheça nossa solução para Analytics e BI</h5>
          <ProductCard fluid product="taxdashs" />
        </Container>
      </S.Solucoes>
    </Layout>
  );
}

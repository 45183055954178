import React from "react";

import * as S from "./style";

export default function Checkbox({ className, mt, mb, label, checked, fs, ...props }) {
  return (
    <S.Checkbox $checked={checked} className={className} $mt={mt} $mb={mb} $fs={fs}>
      <label>
        <S.CheckboxContainer>
          <S.HiddenCheckbox checked={checked} {...props} />
          <S.StyledCheckbox checked={checked}>
            <S.Icon viewBox="0 0 24 24">
              <polyline points="20 6 9 17 4 12" />
            </S.Icon>
          </S.StyledCheckbox>
        </S.CheckboxContainer>
        <span>{label}</span>
      </label>
    </S.Checkbox>
  );
}

import styled from "styled-components";

export const Checkbox = styled.div`
  display: inline-block;
  cursor: pointer;
  margin-top: ${(p) => (p.$mt ? p.$mt : "0")}px;
  margin-bottom: ${(p) => (p.$mb ? p.$mb : "0")}px;
  padding-top: 12px;

  label {
    display: flex;
    align-items: flex-start;
    color: ${(p) => p.theme.textDarkerGray};
  }

  span {
    margin-left: 8px;
    font-size: ${(props) => props.$fs || "20px"};
    cursor: pointer;
    display: inline-block;
  }
`;

//Accessible checkbox
export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

export const StyledCheckbox = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
  background: ${(props) => (props.checked ? "salmon" : "#E1E6E8")};
  border-radius: 0 4px;
  cursor: pointer;
  transition: all 150ms;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }

  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;
